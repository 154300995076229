'use client';

import ErrorView from '@edeeone/edee-stage/errorHandling/ErrorView';
import type { NextError } from 'next/dist/lib/is-error';

export default function ClientError({
  error,
  reset,
}: {
  error: NextError & { variables?: string };
  reset: () => void;
}) {
  return (
    <>
      <ErrorView
        reload={() => {
          // TODO: I don't know why, but reset function not make refreshing  content
          // reset();
          window.location.reload();
        }}
        statusCode={'500'}
        showLogo={false}
        digest={error.digest}
        hideErrorCode={true}
      />
    </>
  );
}
